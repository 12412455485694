import { gql } from '@apollo/client';

export const GET_REFERRALS_QUERY = gql`
  query getReferrals(
    $filters: ReferralFiltersInput
    $pagination: PaginationArg
    $sort: [String]
  ) {
    referrals(pagination: $pagination, filters: $filters, sort: $sort) {
      data {
        id
        attributes {
          email
          name
          payout
          carry
          status
          code
          member {
            data {
              id
              attributes {
                accountDetails {
                  firstName
                  lastName
                }
                avatar {
                  data {
                    attributes {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
      meta {
        pagination {
          page
          pageSize
          total
          pageCount
        }
      }
    }
  }
`;

export const GET_REFERRAL_QUERY = gql`
  query getReferral($id: ID!) {
    referral(id: $id) {
      data {
        id
        attributes {
          email
          name
          code
          payout
          carry
          status
          member {
            data {
              id
              attributes {
                OPP {
                  merchantId
                  profileId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CREATE_REFERRAL_QUERY = gql`
  mutation createManyReferrals($referrals: [ReferralInput!]!) {
    createManyReferrals(referrals: $referrals) {
      referrals {
        code
        email
        name
        status
        payout
        carry
      }
      errors {
        email
        error
      }
    }
  }
`;

export const UPDATE_REFERRAL_QUERY = gql`
  mutation updateReferral($id: ID!, $status: ENUM_REFERRAL_STATUS) {
    updateReferral(id: $id, data: { status: $status }) {
      data {
        id
      }
    }
  }
`;
