import { Grid, Flex, Space } from 'antd';
import Link from 'next/link';
import { posthog } from 'posthog-js';
import { motion } from 'framer-motion';

import {
  MenuContainer,
  MenuBackground,
  MenuSupergraphicWrapper,
  MenuSupergraphic,
  MenuLinks,
  MenuLink,
  MenuFooter,
  MenuSocialIcon,
  MenuFooterDescription,
} from './styles';

import supergraphicImage from 'assets/images/menu-supergraphic.png';
import { useEffect, useRef, useState } from 'react';

const handleSocialClick = (platform: string) => {
  posthog.capture('social_link_clicked', {
    platform,
  });
};

interface BurgerMenuProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const BurgerMenu = ({ isOpen, setIsOpen }: BurgerMenuProps) => {
  const screens = Grid.useBreakpoint();
  const [shouldMoveUp, setShouldMoveUp] = useState(true);
  const [viewport, setViewport] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    handleResize(); // Set initial size
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Calculate the size of the 45-degree cut
  // Using the smaller of width/height to ensure it stays proportional
  const cutSize = Math.min(viewport.width, viewport.height) * 0.13; // 20% of the smaller dimension

  // Calculate the extra space needed to cover the diagonal gap
  const extraSpace = cutSize * Math.SQRT2; // Math.SQRT2 ≈ 1.414, diagonal of a square

  useEffect(() => {
    if (isOpen) {
      setShouldMoveUp(false);
    } else {
      setTimeout(() => {
        setShouldMoveUp(true);
      }, 300);
    }
  }, [isOpen]);

  const isMobile = !screens.md;

  const renderSocialLink = (src, url, platform) => (
    <Link
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={() => handleSocialClick(platform)}
    >
      <MenuSocialIcon
        src={src}
        alt="Logo"
        width={15}
        height={15}
        $isOpen={isOpen}
      />
    </Link>
  );

  const renderLink = (url, copy) => (
    <MenuLink
      href={url}
      onClick={() => setIsOpen(false)}
      $isOpen={isOpen}
      $shouldMoveUp={shouldMoveUp}
      $isMobile={isMobile}
    >
      {copy}
    </MenuLink>
  );

  const basePath = `M${viewport.width + extraSpace} 0 
    L${viewport.width + extraSpace} ${viewport.height + extraSpace} 
    L0 ${viewport.height + extraSpace} 
    L-${extraSpace} ${viewport.height} 
    L-${extraSpace} 0 Z`;

  const pathVariants = {
    closed: {
      d: `M${viewport.width + extraSpace} 0 
          L${viewport.width + extraSpace} ${viewport.height + extraSpace} 
          L0 ${viewport.height + extraSpace} 
          L-${extraSpace} ${viewport.height} 
          L-${extraSpace} 0 Z`,
      transform: `translate(${viewport.width + 150}px, -${viewport.height + 150}px)`,
      transition: { duration: 1, ease: [0.76, 0, 0.24, 1] },
    },
    open: {
      d: basePath,
      transform: 'translate(0px, 0px)',
      transition: { duration: 1, ease: [0.25, 1, 0.5, 1] },
    },
  };

  return (
    <MenuContainer $isOpen={isOpen} $isMobile={isMobile}>
      <svg width={viewport.width} height={viewport.height}>
        <defs>
          <clipPath id="polygonClipPath">
            <motion.path
              variants={pathVariants}
              initial="closed"
              animate={isOpen ? 'open' : 'closed'}
              d={pathVariants.open.d}
            />
          </clipPath>
        </defs>
      </svg>
      <MenuBackground $isOpen={isOpen} />
      <MenuSupergraphicWrapper $isOpen={isOpen}>
        <MenuSupergraphic
          $isOpen={isOpen}
          $isMobile={isMobile}
          src={supergraphicImage}
          alt=""
          quality={100}
        />
      </MenuSupergraphicWrapper>
      <MenuLinks
        $isOpen={isOpen}
        $shouldMoveUp={shouldMoveUp}
        $isMobile={isMobile}
      >
        {renderLink('/insights/what-is-shuttle', 'What is Shuttle?')}
        {renderLink('/insights/who-is-shuttle-for', 'Who is it for?')}
        {renderLink('/insights/how-does-shuttle-work', 'How does it work?')}
      </MenuLinks>
      <MenuFooter
        justify="start"
        align="bottom"
        $isOpen={isOpen}
        $isMobile={isMobile}
      >
        <Flex vertical gap={20}>
          <Space size={25}>
            {renderSocialLink(
              '/images/footer/linkedin.svg',
              'https://www.linkedin.com/company/shuttleclub',
              'linkedin'
            )}
            {renderSocialLink(
              '/images/footer/instragram.svg',
              'https://www.instagram.com/club.shuttle',
              'instagram'
            )}
            {renderSocialLink(
              '/images/footer/twitter.svg',
              'https://x.com/club_shuttle',
              'twitter'
            )}
          </Space>
          <MenuFooterDescription type="secondary" $isMobile={isMobile}>
            PitchedIt Ltd t/a Shuttle Finance is regulated by the Central Bank
            of Ireland. Registered with the Companies Registration Office in
            Ireland. Company number 697037.
          </MenuFooterDescription>
        </Flex>
      </MenuFooter>
    </MenuContainer>
  );
};

export default BurgerMenu;
