import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { Col, Row, Tooltip, message } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import formatDistance from 'date-fns/formatDistance';
import { useSession } from 'next-auth/react';
import { useMutation } from '@apollo/client';
import * as R from 'ramda';

import { NOTIFICATIONS_QUERY, DELETE_NOTIFICATION_QUERY } from 'queries';

import { NotificationProps } from 'types/notification';

import { LinkContent, Title, Subject, Time, Resolve } from './styles';

interface NotificationComponentProps {
  notification: NotificationProps;
  setNotificationsOpen(open: boolean): void;
  limit: number;
}

function Notification({
  notification,
  setNotificationsOpen,
  limit,
}: NotificationComponentProps) {
  const { data: session } = useSession();
  const [deleteLoading, setDeleteLoading] = useState(false);
  const router = useRouter();

  const [deleteNotification] = useMutation(DELETE_NOTIFICATION_QUERY, {
    update(cache, { data: { deleteNotification } }) {
      const variables = {
        pagination: {
          start: 0,
          limit,
        },
        filters: {
          receiver: { id: { eq: session?.user.id } },
        },
      };

      const cachedNotifications: any = cache.readQuery({
        query: NOTIFICATIONS_QUERY,
        variables,
      });

      if (!cachedNotifications?.notifications) return;

      cache.writeQuery({
        query: NOTIFICATIONS_QUERY,
        variables,
        data: {
          notifications: {
            __typename: 'NotificationEntityResponseCollection',
            meta: {
              pagination: {
                total:
                  cachedNotifications?.notifications.meta.pagination.total - 1,
              },
            },
            data: R.reject(
              R.propEq(deleteNotification.data.id, 'id'),
              cachedNotifications?.notifications.data
            ),
          },
        },
      });

      if (cachedNotifications?.notifications.meta.pagination.total - 1 === 0) {
        setNotificationsOpen(false);
      }
    },
    onCompleted() {
      setDeleteLoading(false);
    },
    onError(error) {
      console.log('notification error', error);
      message.error(
        'There was an error resolving your notification. Please try again later.'
      );
      setDeleteLoading(false);
    },
  });

  const timeElapsed = formatDistance(
    new Date(notification.createdAt),
    new Date(),
    {
      addSuffix: true,
    }
  );

  const withLink = (children) => {
    const shallow = router.asPath === notification.link?.split('?')[0];
    return notification.link ? (
      <Link
        href={notification.link.replace(`${process.env.NEXTAUTH_URL}`, '')}
        shallow={shallow}
        style={{ width: '100%' }}
        onClick={() => {
          setNotificationsOpen(false);
        }}
      >
        {children}
      </Link>
    ) : (
      children
    );
  };

  return (
    <LinkContent $seen={notification.status === 'seen'}>
      {withLink(
        <Row align="middle" wrap={false} style={{ width: '100%' }}>
          <Col flex="auto">
            <Title level={5}>{notification.subject}</Title>
            {notification.message && (
              <Subject
                ellipsis={{
                  rows: 2,
                  expandable: true,
                  symbol: (
                    <span className="Notification-ReadMoreButton">
                      read more
                    </span>
                  ),
                }}
              >
                {notification.message}
              </Subject>
            )}
            <Time>{timeElapsed}</Time>
          </Col>
          <Col flex="50px" style={{ textAlign: 'right' }}>
            <Tooltip
              title="Delete"
              placement="topRight"
              overlayInnerStyle={{ padding: '5px 10px', minHeight: 'auto' }}
            >
              <Resolve
                loading={deleteLoading}
                type="primary"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setDeleteLoading(true);
                  deleteNotification({ variables: { id: notification.id } });
                }}
              />
            </Tooltip>
          </Col>
        </Row>
      )}
    </LinkContent>
  );
}

export default Notification;
