import { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import { Dropdown, Button, Row, Col, Space, Grid, Divider, Badge } from 'antd';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import Image from 'next/image';
import { useInView } from 'framer-motion';
import posthog from 'posthog-js';

import Notifications from 'components/Notifications';
import WaitlistModal from 'components/WaitlistModal';

import BurgerMenu from './Menu';

import useUserData from 'hooks/useUserData';
import { useLogoState } from 'context/LogoContext';
import { annualFeePaid } from 'utils/oppHelpers';
import { capitalizeFirstLetter } from 'utils/strings';

import theme from 'styles/theme';
import {
  BurgerButton,
  BurgerLine,
  HeaderContainer,
  Logo,
  DropDownActiveItem,
  MainLink,
  MainLinkNoHref,
} from './styles';

function Header() {
  const { data: session } = useSession();
  const screens = Grid.useBreakpoint();
  const router = useRouter();
  const containerRef = useRef(null);
  const isInView = useInView(containerRef, { amount: 0.5, once: true });

  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false);
  const [waitlistCtaCopy, setWaitlistCtaCopy] = useState('Get early access');
  const [activeItem, setActiveItem] = useState(
    capitalizeFirstLetter(router.route.split('/')[1])
  );
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [previousDarkBackground, setPreviousDarkBackground] =
    useState<boolean>(true);

  const { isDarkBackground, setIsDarkBackground, isSolidBackground } =
    useLogoState();
  const { userData } = useUserData();

  const isApply = router.route.includes('apply');
  const isSignIn = router.route.includes('login');
  const isRegister = router.route.includes('register');
  const is2fa = router.route.includes('2fa');
  const isOnboarding = router.route.includes('onboarding');
  const isConfirmEmail = router.route.includes('confirm-email');
  const isAuthFlow =
    isSignIn || is2fa || isOnboarding || isRegister || isConfirmEmail;

  const feesPaid = annualFeePaid(userData?.OPP?.annualFee);
  const kycApproved = userData?.OPP?.kycApproved;
  const showAccountBadge = !feesPaid || !kycApproved;

  useEffect(() => {
    const nextPath = capitalizeFirstLetter(router.route.split('/')[1]);
    if (nextPath !== activeItem) {
      setActiveItem(nextPath);
    }
  }, [router.route, activeItem]);

  const menuItems = [
    {
      key: 'Dashboard',
      label: (
        <MainLink href="/dashboard" $isActive={activeItem === 'Dashboard'}>
          Dashboard
        </MainLink>
      ),
    },
    {
      key: 'Opportunities',
      label: (
        <MainLink
          href="/opportunities"
          $isActive={activeItem === 'Opportunities'}
        >
          Opportunities
        </MainLink>
      ),
    },
    {
      key: 'Portfolio',
      label: (
        <MainLink href="/portfolio" $isActive={activeItem === 'Portfolio'}>
          Portfolio
        </MainLink>
      ),
    },
    {
      key: 'Account',
      label: (
        <Badge dot={showAccountBadge} offset={[4, 2]} size="small">
          <MainLink href="/account" $isActive={activeItem === 'Account'}>
            Account
          </MainLink>
        </Badge>
      ),
    },
  ];

  if (userData?.role?.name === 'Super Admin') {
    menuItems.push({
      key: 'Admin',
      label: (
        <MainLink href="/admin" $isActive={activeItem === 'Admin'}>
          Admin
        </MainLink>
      ),
    });
  }

  const isMobile = !screens.md;

  return (
    <>
      <HeaderContainer
        ref={containerRef}
        $isInView={isInView}
        $isHome={router.route === '/'}
        $isSolidBackground={isSolidBackground}
        $isDarkBackground={isDarkBackground}
        $isMobile={isMobile}
      >
        <WaitlistModal
          isOpen={waitlistModalOpen}
          setIsOpen={setWaitlistModalOpen}
          onComplete={() => {}}
        />
        <Row
          style={{
            padding: screens.md ? '30px 60px 25px' : '30px 30px 15px',
            position: 'relative',
            margin: '0 auto',
            alignItems: 'center',
          }}
          justify="space-between"
        >
          <Col sm={12} md={12}>
            <Link
              href={session ? '/dashboard' : '/'}
              style={{
                width: screens.md ? 150 : 100,
                display: 'flex',
              }}
            >
              <Logo
                src="/images/shuttle-icon.svg"
                loading="eager"
                width={240}
                height={338}
                alt="Logo"
                $isDarkBackground={isDarkBackground}
              />
            </Link>
          </Col>
          <Col sm={12} md={12}>
            <Row justify="end" align="bottom">
              {session && !isAuthFlow && (
                <Space size={30}>
                  {session && !isAuthFlow && (
                    <Dropdown
                      menu={{
                        items: menuItems,
                        selectable: false,
                      }}
                    >
                      <Space>
                        <DropDownActiveItem
                          $isDarkBackground={isDarkBackground}
                        >
                          {activeItem}
                        </DropDownActiveItem>
                        <Image
                          src="/images/caret-left.svg"
                          width={6}
                          height={12}
                          alt="Caret"
                          style={{
                            filter: `invert(${isDarkBackground ? 1 : 0}) brightness(1.2)`,
                            transform: 'rotate(-90deg) translateX(-2px)',
                            height: 12,
                            width: 'auto',
                          }}
                        />
                      </Space>
                    </Dropdown>
                  )}
                  <MainLinkNoHref
                    $isDarkBackground={isDarkBackground}
                    onClick={() => {
                      signOut({
                        callbackUrl: `${process.env.NEXTAUTH_URL}`,
                      });
                    }}
                  >
                    Sign out
                  </MainLinkNoHref>
                  <Notifications isDarkBackground={isDarkBackground} />
                </Space>
              )}
              {!session && !isAuthFlow && (
                <Space size={isMobile ? 15 : 30}>
                  {/* <Link href="/auth/register"> */}
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: isMenuOpen && theme.colors.white,
                    }}
                    onClick={() => {
                      setWaitlistModalOpen(true);
                      posthog.capture('header_waitlist_cta_click');
                    }}
                  >
                    {waitlistCtaCopy}
                  </Button>
                  {/* </Link> */}
                  <BurgerButton
                    $isOpen={isMenuOpen}
                    $isDarkBackground={isDarkBackground}
                    onClick={() => {
                      if (!isMenuOpen) {
                        setPreviousDarkBackground(isDarkBackground);
                        setIsDarkBackground(false);
                      } else {
                        setIsDarkBackground(previousDarkBackground);
                      }
                      setIsMenuOpen(!isMenuOpen);
                    }}
                    aria-label="Toggle menu"
                  >
                    <BurgerLine
                      $isOpen={isMenuOpen}
                      $isDarkBackground={isDarkBackground}
                    />
                    <BurgerLine
                      $isOpen={isMenuOpen}
                      $isDarkBackground={isDarkBackground}
                    />
                    <BurgerLine
                      $isOpen={isMenuOpen}
                      $isDarkBackground={isDarkBackground}
                    />
                  </BurgerButton>
                </Space>
              )}
            </Row>
          </Col>
        </Row>
      </HeaderContainer>
      <BurgerMenu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    </>
  );
}

export default Header;
