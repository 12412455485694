import styled from 'styled-components';
import { timingFunctions } from 'polished';
import { Typography } from 'antd';

import theme from 'styles/theme';

interface CommonProps {
  $isComplete: boolean;
  $hasRendered: boolean;
  $isMobile?: boolean;
  $displayProgress?: number;
}

export const LoaderContainer = styled.div<CommonProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.colors.lightGrey};
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: height 1s ${timingFunctions('easeInOutQuint')} 0.2s;
  height: ${({ $isComplete }) => ($isComplete ? '0' : '100%')};
`;

export const LoaderContent = styled.div<CommonProps>`
  position: relative;

  &:after {
    display: block;
    content: ${({ $displayProgress }) => `'${$displayProgress}%'`};
    position: absolute;
    bottom: ${({ $isMobile }) => ($isMobile ? '-15px' : '-25px')};
    right: ${({ $isMobile }) => ($isMobile ? '-45px' : '-55px')};
    font-size: ${({ $isMobile }) => ($isMobile ? '20px' : '26px')};
    opacity: ${({ $isComplete, $hasRendered }) =>
      $isComplete ? 0 : $hasRendered ? 1 : 0};
    font-weight: 500;
    letter-spacing: -1px;
  }
`;

export const LoaderSVG = styled.svg<CommonProps>`
  position: relative;
  overflow: visible;
  width: ${({ $isMobile }) => ($isMobile ? '180px' : '300px')};
  height: auto;
  transition: opacity 0.5s ${timingFunctions('easeOutCubic')};
  opacity: ${({ $isComplete, $hasRendered }) =>
    $isComplete ? 0 : $hasRendered ? 1 : 0};
`;
