import { gql } from '@apollo/client';

export const GET_NOTIFICATION_COPY_QUERY = gql`
  query {
    notificationCopy {
      data {
        attributes {
          content {
            type
            subject
            message
            linkText
          }
        }
      }
    }
  }
`;

export const NOTIFICATIONS_QUERY = gql`
  query getNotifications(
    $pagination: PaginationArg
    $filters: NotificationFiltersInput
  ) {
    notifications(
      sort: "createdAt:desc"
      pagination: $pagination
      filters: $filters
    ) {
      meta {
        pagination {
          total
        }
      }
      data {
        id
        attributes {
          type
          createdAt
          skipPush
          skipEmail
          subject
          message
          link
          linkText
          status
        }
      }
    }
  }
`;

export const UPDATE_NOTIFICATIONS_QUERY = gql`
  mutation markNotificationsSeen {
    markNotificationsSeen {
      count
    }
  }
`;

export const CREATE_NOTIFICATION_QUERY = gql`
  mutation createNotification(
    $receiver: ID!
    $type: ENUM_NOTIFICATION_TYPE!
    $skipPush: Boolean
    $subject: String!
    $message: String!
    $link: String
    $linkText: String
    $status: ENUM_NOTIFICATION_STATUS
    $feedback: ID
  ) {
    createNotification(
      data: {
        receiver: $receiver
        type: $type
        skipPush: $skipPush
        subject: $subject
        message: $message
        link: $link
        linkText: $linkText
        status: $status
        feedback: $feedback
      }
    ) {
      data {
        id
      }
    }
  }
`;

export const DELETE_NOTIFICATION_QUERY = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id) {
      data {
        id
      }
    }
  }
`;
