import * as R from 'ramda';

export const annualFeePaid = (annualFee) =>
  R.allPass([
    R.propEq('completed', 'status'),
    R.propSatisfies(
      (renewalDate) => new Date(renewalDate) > new Date(),
      'renewalDueDate'
    ),
  ])(annualFee);

export const getValueFromMeta = (key, metadata) =>
  R.prop('value', R.find(R.propEq(key, 'key'))(metadata));

export const formatPaymentMethod = (payment_method) => {
  switch (payment_method) {
    case 'ideal':
      return 'iDEAL';
    case 'bcmc':
      return 'Bancontact';
    case 'sepa':
      return 'SEPA';
    case 'paypal':
      return 'PayPal';
    case 'creditcard':
      return 'Card';
    case 'sofort':
      return 'Sofort';
    case 'mybank':
      return 'MyBank';
    case 'giropay':
      return 'GiroPay';
    case 'mandate':
      return 'Mandate';
    case 'multi':
      return 'Multi-split';
    default:
      return 'Unknown';
  }
};

export const formatAmountForOPP = (amount: number): number => amount * 100;
export const formatAmountForPIL = (amount: number): number => amount / 100;
