import styled from 'styled-components';
import { Typography, List, Button } from 'antd';

import theme from 'styles/theme';

interface LinkContentProps {
  $seen: boolean;
}

export const LinkContent = styled(List.Item)<LinkContentProps>`
  &&& {
    flex-wrap: nowrap;
    flex-direction: row;
    align-items: center;
    transition: 0.1s ease-out;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    background-color: ${(props) =>
      props.$seen ? 'white' : theme.colors.lightGrey}50;
    padding: 15px 24px;
    transition: background-color 0.5s ease-out;
    user-select: none;

    &:hover {
      background-color: ${theme.colors.lightGrey};
    }
  }
`;

export const Title = styled(Typography.Title)`
  &&& {
    margin-bottom: 5px;
    font-weight: 600;
  }
`;

export const Subject = styled(Typography.Paragraph)`
  &&& {
    margin-bottom: 5px;
  }
`;

export const Time = styled.span`
  font-size: 14px;
  display: block;
  color: ${theme.colors.textSecondary};
`;

export const Resolve = styled(Button)``;
