import type { ThemeConfig } from 'antd';

import theme from 'styles/theme';

const antdTheme: ThemeConfig = {
  token: {
    borderRadius: 15,
    colorBgBase: theme.colors.white,
    colorError: theme.colors.error,
    colorInfo: theme.colors.lavender,
    colorLink: theme.colors.lavenderGrey,
    colorLinkHover: theme.colors.darkTeal,
    colorPrimary: theme.colors.darkTeal,
    colorSuccess: theme.colors.success,
    colorTextBase: theme.colors.black,
    colorText: theme.colors.black,
    colorTextHeading: theme.colors.black,
    colorTextSecondary: theme.colors.textSecondary,
    colorWarning: theme.colors.warning,
    colorPrimaryActive: theme.colors.darkTeal,
    controlHeight: 48,
    controlHeightLG: 48,
    controlHeightSM: 24,
    fontFamily: 'Aeonik',
    fontSize: 16,
  },
  components: {
    Form: {
      labelColor: '#909FAE',
      itemMarginBottom: 15,
    },
    Button: {
      colorPrimary: theme.colors.chartreuse,
      primaryColor: theme.colors.black,
      colorPrimaryHover: theme.colors.lavender,
      colorPrimaryTextHover: theme.colors.white, // hardcoded in global.ts line 226
      colorBorder: '#D0D0D0',
      defaultBg: 'transparent',
      defaultHoverBg: theme.colors.lavender,
      defaultColor: theme.colors.black,
      defaultHoverColor: theme.colors.black,
      defaultBorderColor: '#B0B7D2',
      defaultHoverBorderColor: theme.colors.lavender,
      colorErrorHover: theme.colors.white,
      colorErrorBgFilledHover: theme.colors.error,
      colorErrorBorderHover: theme.colors.error,
      fontFamily: 'Aeonik',
      fontWeight: 600,
      defaultShadow: 'none',
      primaryShadow: 'none',
      dangerShadow: 'none',
      colorText: theme.colors.black,
      borderColorDisabled: 'none',
      contentFontSizeSM: 14,
      lineWidth: 1,
      borderRadiusLG: 50,
      borderRadius: 50,
      paddingInline: 20,
    },
    Typography: {
      titleMarginTop: 0,
      titleMarginBottom: 15,
      fontSizeHeading1: 48,
      fontWeightStrong: 500,
      lineHeight: 1.3,
      lineHeightHeading1: 1.1,
      lineHeightHeading2: 1.1,
      lineHeightHeading3: 1.2,
      lineHeightHeading4: 1.2,
    },
    Input: {
      borderRadius: 25,
      borderRadiusLG: 25,
      paddingInline: 20,
      paddingInlineLG: 20,
      hoverBorderColor: theme.colors.darkTeal,
      activeBorderColor: theme.colors.darkTeal,
      activeShadow: `0 0 0 2px ${theme.colors.darkTeal}50`,
    },
    InputNumber: {
      borderRadius: 25,
      borderRadiusLG: 25,
      paddingInline: 20,
      paddingInlineLG: 20,
      hoverBorderColor: theme.colors.darkTeal,
      activeBorderColor: theme.colors.darkTeal,
      activeShadow: `0 0 0 2px ${theme.colors.darkTeal}50`,
    },
    Select: {
      multipleItemBg: theme.colors.lightGrey,
      optionActiveBg: theme.colors.lightGrey,
      optionSelectedBg: theme.colors.lightGrey,
      optionSelectedColor: theme.colors.darkTeal,
      borderRadius: 25,
      // showArrowPaddingInlineEnd: 20,
      // controlPaddingHorizontal: 20,
    },
    DatePicker: {
      borderRadius: 25,
      paddingInline: 20,
      fontFamily: 'Aeonik',
    },
    Statistic: {
      colorTextDescription: theme.colors.black,
    },
    Modal: {
      paddingMD: 0,
      paddingContentHorizontalLG: 0,
      contentBg: theme.colors.white,
    },
    Table: {
      headerColor: theme.colors.darkTeal,
      fontWeightStrong: 400,
    },
    Tooltip: {
      colorBgSpotlight: `${theme.colors.black}d1`,
      controlHeight: 36,
    },
    Radio: {
      borderRadius: 25,
      // buttonSolidCheckedBg: theme.colors.chartreuse,
      // buttonSolidCheckedColor: theme.colors.black,
      // buttonSolidCheckedHoverBg: `${theme.colors.chartreuse}80`,
      // buttonSolidCheckedActiveBg: theme.colors.chartreuse,
    },
    Tour: {
      zIndexPopup: 1071,
    },
    Progress: {
      defaultColor: theme.colors.darkTeal,
    },
    Notification: {
      colorInfo: theme.colors.darkTeal,
    },
    Message: {
      colorInfo: theme.colors.darkTeal,
    },
  },
};

export default antdTheme;
