import * as R from 'ramda';

export const currencyFormatter = (value, digits = 0) =>
  new Intl.NumberFormat('en-IE', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(value);

export const capitalizeFirstLetter = (string = '') =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const abbreviateNumber = (value, decimals = 2) => {
  function formatNumber(n, divisor, suffix) {
    const num = n / divisor;

    // Show decimals only if the number is below 100; otherwise, no decimals
    if (num >= 100) {
      return `${Math.floor(num)}${suffix}`;
    } else {
      return num.toFixed(decimals).replace(/\.?0+$/, '') + suffix;
    }
  }

  if (value < 1000) {
    return value.toFixed(decimals); // You can keep this as it is if you need decimals for numbers < 1000
  } else if (value >= 1000 && value < 1000000) {
    return formatNumber(value, 1000, 'K');
  } else if (value >= 1000000 && value < 1000000000) {
    return formatNumber(value, 1000000, 'M');
  } else if (value >= 1000000000) {
    return formatNumber(value, 1000000000, 'B');
  }
};
