import { useState, useEffect } from 'react';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import NProgress from 'nprogress';
import { ApolloProvider } from '@apollo/client';
import { SessionProvider } from 'next-auth/react';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { App as AntApp, ConfigProvider } from 'antd';
import { IntercomProvider } from 'react-use-intercom';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

import { useApollo } from 'lib/apollo/client';

import { LogoProvider } from 'context/LogoContext';
import { LoaderProvider } from 'context/LoaderContext';

import Header from 'components/Header';
import Footer from 'components/Footer';
import CookieBanner from 'components/CookieBanner';
import Providers from 'components/Providers';
import Loader from 'components/Loader';

import GlobalStyles from 'styles/global';
import antdTheme from 'styles/antdTheme';

import 'styles/fonts.css';
import 'styles/nprogress.css';

if (process.env.NODE_ENV === 'development') {
  loadDevMessages();
  loadErrorMessages();
}

if (typeof window !== 'undefined') {
  import('utils/cookieBannerStorage').then(({ default: storage }) => {
    const { cookiesEnabled } = storage.getData();

    posthog.init(process.env.POSTHOG_KEY, {
      api_host: process.env.POSTHOG_HOST || 'https://eu.i.posthog.com',
      person_profiles: 'always',
      persistence: cookiesEnabled ? 'localStorage+cookie' : 'memory',
      debug: false,
      // loaded: (posthog) => {
      //   if (process.env.NODE_ENV === 'development') posthog.debug();
      // },
    });
  });
}

const App = ({ Component, pageProps, router }: AppProps) => {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const [currentPath, setCurrentPath] = useState(router.route);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const pageRequiresLoader = ['/'].includes(router.route);

  useEffect(() => {
    const startURLChange = (path) => {
      if (window.location.pathname !== path.split('?')[0]) {
        NProgress.start();
      }
    };

    const endURLChange = () => {
      NProgress.done();
      if (window.location.pathname !== currentPath.split('?')[0]) {
        window.scrollTo(0, 0);
        setCurrentPath(router.route);
      }
    };

    Router.events.on('routeChangeStart', startURLChange);
    Router.events.on('routeChangeComplete', endURLChange);
    Router.events.on('routeChangeError', endURLChange);

    return () => {
      Router.events.off('routeChangeStart', startURLChange);
      Router.events.off('routeChangeComplete', endURLChange);
      Router.events.off('routeChangeError', endURLChange);
    };
  }, []);

  useEffect(() => {
    if (isInitialLoad && router.route !== '/') {
      setIsInitialLoad(false);
    }
  }, [router.route, isInitialLoad]);

  return (
    <Providers
      providers={[
        {
          ProviderComponent: PostHogProvider,
          props: { client: posthog },
        },
        {
          ProviderComponent: ApolloProvider,
          props: { client: apolloClient },
        },
        {
          ProviderComponent: SessionProvider,
          props: { session: pageProps.session },
        },
        {
          ProviderComponent: ConfigProvider,
          props: { theme: antdTheme },
        },
        {
          ProviderComponent: IntercomProvider,
          props: {
            appId: process.env.INTERCOM_APP_ID,
            autoBoot: true,
          },
        },
        {
          ProviderComponent: LogoProvider,
        },
        {
          ProviderComponent: LoaderProvider,
        },
      ]}
    >
      <AntApp>
        <GlobalStyles />
        <Header />
        {pageRequiresLoader && isInitialLoad && (
          <Loader progress={0} isInitialLoad={isInitialLoad} />
        )}
        <Component key={router.route} router={router} {...pageProps} />
        {currentPath !== '/' && <Footer />}
        <CookieBanner cookieBanner={pageProps.cookieBanner} />
      </AntApp>
    </Providers>
  );
};

export default App;
