import type { NextRequest } from 'next/server';
import * as R from 'ramda';

export async function fetchGetJSON(url: string, token?: string) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : null,
      },
    });
    return await response.json();
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function fetchPostJSON(
  url: string,
  token?: string,
  data?: Record<string, any>,
  extraHeaders: Record<string, any> = {}
) {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : null,
        ...extraHeaders,
      },
      body: JSON.stringify(data || {}),
    });
    return await response.json();
  } catch (err) {
    throw new Error(err.message);
  }
}

export async function fetchPutJSON(
  url: string,
  token?: string,
  data?: Record<string, any>,
  extraHeaders: Record<string, any> = {}
) {
  try {
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: token ? `Bearer ${token}` : null,
        ...extraHeaders,
      },
      body: JSON.stringify(data || {}),
    });
    return await response.json();
  } catch (err) {
    throw new Error(err.message);
  }
}

export function parseSignatureHeader(
  signatureHeader: string
): Record<string, string> {
  const components = signatureHeader
    .split(',')
    .map((part) => part.trim().split('='));
  return Object.fromEntries(
    components.map(([key, value]) => [key, value.replace(/"/g, '')])
  );
}

export function constructSigningString(
  request: NextRequest,
  headers: string[]
): string {
  return headers
    .map((header) => {
      if (header === '(request-target)') {
        return `(request-target): ${request.method.toLowerCase()} ${
          request.nextUrl.pathname
        }`;
      }
      return `${header}: ${request.headers.get(header)}`;
    })
    .join('\n');
}

export async function verifySignature(
  signingString: string,
  providedSignatureBase64: string,
  secret: string
): Promise<boolean> {
  const encoder = new TextEncoder();
  const key = await crypto.subtle.importKey(
    'raw',
    encoder.encode(secret),
    { name: 'HMAC', hash: 'SHA-256' },
    false,
    ['verify']
  );

  const providedSignature = Buffer.from(providedSignatureBase64, 'base64');

  return crypto.subtle.verify(
    'HMAC',
    key,
    providedSignature,
    encoder.encode(signingString)
  );
}

const convertToQueryPair = ([key, value]) => `${key}=${value}`;
export const objectToQueryString = R.pipe(
  R.filter(R.isNotNil),
  R.toPairs,
  R.map(convertToQueryPair),
  R.join('&')
);
