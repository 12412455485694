import { gql } from '@apollo/client';

export const getKIISFragment = gql`
  fragment GetKIISFields on KeyInvestmentInformationSheet {
    offerIdentifier
    project {
      data {
        id
        attributes {
          name
          legalName
          companyNumber
          incorporationCountry
          founderProfiles {
            linkedInUrl
            name
          }
          url
        }
      }
    }
    typeOfOffer
    instrumentType
    deadline
    legalName
    projectName
    incorporationCountry
    legalForm
    contactDetails
    beneficialOwners {
      fullname
      percentageOwnership
    }
    lastOwnershipChange
    financialStatements {
      data {
        attributes {
          url
        }
      }
    }
    keyAnnualFinancials {
      type
      yearOne
      yearTwo
      yearThree
    }
    descriptionOfProject
    previousCampaigns {
      typeOfOffer
      instrumentType
      completionDate
      amountRaised
      otherRelevantInfo
    }
    minimumAmount
    maximumAmount
    targetAmount
    committedByShareholders
    targetNotReached
    changeOfCapitalComposition
    projectRisks
    sectorRisks
    riskOfDefault
    riskOfReturns
    riskOfPlatformFailure
    riskOfIlliquidity
    otherRisks
    securitiesOffered
    subscriptionPrice
    overSubscriptionsAccepted
    termsOfSubscription
    custodyOfSecurities
    spv
    spvContactDetails
    keyRights
    keyRestrictions
    exitOpportunities
    distributionOfCapital {
      data {
        attributes {
          url
        }
      }
    }
    investmentFee
    performanceFee
    additionalInformation
    complaints
    projectOwnerAndName
    countryOfIncorporation
    registrationNumber
    management
    responsibilityOfInformation
    principleActivities
    status
    exportedPDF {
      data {
        attributes {
          url
        }
      }
    }
    rejectionReasons {
      reason
      dateCreated
      addressed
    }
  }
`;

export const GET_KIIS_QUERY = gql`
  ${getKIISFragment}
  query getKeyInvestmentInformationSheet($project: ID) {
    kiis: keyInvestmentInformationSheets(
      filters: { project: { id: { eq: $project } } }
    ) {
      data {
        id
        attributes {
          ...GetKIISFields
        }
      }
    }
  }
`;

export const CREATE_KIIS_QUERY = gql`
  mutation createKeyInvestmentInformationSheet($project: ID!) {
    createKeyInvestmentInformationSheet(data: { project: $project }) {
      data {
        id
      }
    }
  }
`;

export const UPDATE_KIIS_QUERY = gql`
  ${getKIISFragment}
  mutation updateKeyInvestmentInformationSheet(
    $id: ID!
    $fields: KeyInvestmentInformationSheetInput!
  ) {
    updateKeyInvestmentInformationSheet(id: $id, data: $fields) {
      data {
        id
        attributes {
          ...GetKIISFields
        }
      }
    }
  }
`;
