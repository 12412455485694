import { timingFunctions } from 'polished';
import styled from 'styled-components';
import theme from 'styles/theme';
import Image from 'next/image';
import { Row, Space, Typography } from 'antd';
import Link from 'next/link';

interface CommonProps {
  $isOpen?: boolean;
  $shouldMoveUp?: boolean;
  $isMobile?: boolean;
}

export const MenuContainer = styled.div<CommonProps>`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  z-index: 999;
  overflow: hidden;
  pointer-events: ${({ $isOpen }) => ($isOpen ? 'all' : 'none')};
`;

export const MenuBackground = styled.div<CommonProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${theme.colors.chartreuse};
  clip-path: url(#polygonClipPath);
  z-index: 1;
`;

export const MenuSupergraphicWrapper = styled.div<CommonProps>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  clip-path: url(#polygonClipPath);
`;

export const MenuSupergraphic = styled(Image)<CommonProps>`
  position: absolute;
  top: -10px;
  right: ${({ $isMobile }) => ($isMobile ? '-700px' : '-20%')};
  opacity: ${({ $isMobile }) => ($isMobile ? 0.25 : 1)};
  height: calc(100% + 90px);
  width: auto;
  transform: ${({ $isOpen }) =>
    $isOpen ? 'scale(1) translate(0, 0)' : 'scale(1.1) translate(50px, -50px)'};
  transition: transform 1s ${timingFunctions('easeOutQuint')}
    ${({ $isOpen }) => ($isOpen ? '0s' : '1s')};
`;

export const MenuLinks = styled.div<CommonProps>`
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ $isMobile }) => ($isMobile ? '30px' : '60px')};
  z-index: 2;
  display: flex;
  flex-direction: column;
  gap: ${({ $isMobile }) => ($isMobile ? '10px' : '20px')};
`;

export const MenuLink = styled(Link)<CommonProps>`
  display: block;
  width: fit-content;
  white-space: nowrap;
  font-size: ${({ $isMobile }) => ($isMobile ? '30px' : '7vw')};
  font-weight: 500;
  color: ${theme.colors.black};
  line-height: ${({ $isMobile }) => ($isMobile ? '40px' : '7vw')};
  letter-spacing: ${({ $isMobile }) => ($isMobile ? '-1px' : '-2px')};
  opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
  transform: translateY(
    ${({ $isOpen, $shouldMoveUp }) =>
      $isOpen ? '0' : $shouldMoveUp ? '50px' : '0'}
  );
  transition:
    opacity ${({ $isOpen }) => ($isOpen ? '1s' : '0.3s')} ease-out,
    transform 1s ${timingFunctions('easeOutExpo')};

  position: relative;
  /* overflow: hidden; */

  &:nth-of-type(1) {
    transition-delay: ${({ $isOpen }) => ($isOpen ? '0.4s' : '0s')};
  }

  &:nth-of-type(2) {
    transition-delay: ${({ $isOpen }) => ($isOpen ? '0.6s' : '0s')};
  }

  &:nth-of-type(3) {
    transition-delay: ${({ $isOpen }) => ($isOpen ? '0.8s' : '0s')};
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 5px;
    width: calc(100% - 10px);
    height: 0.6vw;
    background-color: ${theme.colors.black};
    opacity: 0;
    transform: scaleX(0);
    transform-origin: left;
    transition:
      opacity 0.3s ${timingFunctions('easeOutQuint')} 0s,
      transform 0s ${timingFunctions('easeInOutQuint')} 0.3s;
  }

  &:hover {
    color: ${theme.colors.black};
  }

  &:hover:after {
    opacity: 1;
    transform: scaleX(1);
    transition:
      opacity 0s ${timingFunctions('easeOutQuart')} 0s,
      transform 0.6s ${timingFunctions('easeInOutQuint')} 0s;
  }
`;

export const MenuFooter = styled(Row)<CommonProps>`
  &&& {
    position: fixed;
    bottom: 60px;
    left: ${({ $isMobile }) => ($isMobile ? '30px' : '60px')};
    z-index: 2;
    width: 100%;
    opacity: ${({ $isOpen }) => ($isOpen ? 1 : 0)};
    transition: opacity ${({ $isOpen }) => ($isOpen ? '1s' : '0.3s')} ease-out
      ${({ $isOpen }) => ($isOpen ? '1.2s' : '0s')};
  }
`;

export const MenuSocialIcon = styled(Image)<CommonProps>`
  &&& {
    font-size: 26px;
    transition: filter 0.2s;
    filter: brightness(0);
    opacity: ${({ $isOpen }) => ($isOpen ? 0.5 : 0)};
    transition: opacity 0.2s;

    &:hover {
      opacity: 1;
    }
  }
`;

export const MenuFooterDescription = styled(Typography.Text)<CommonProps>`
  &&& {
    max-width: ${({ $isMobile }) => ($isMobile ? '85%' : '400px')};
  }
`;
